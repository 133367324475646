<template>
  <el-form inline :model="selectModel" label-position="right">
    <el-form-item label="用户订单ID">
      <el-input v-model="selectModel.userOrderId" />
    </el-form-item>
    <el-form-item label="状态">
      <el-select v-model="selectModel.status" placeholder="请选择" clearable>
        <el-option v-for="(a,b) in $store.state.userRefundStatus" :key="b" :value="b" :label="a" />
      </el-select>
    </el-form-item>
    <el-form-item label="创建时间">
      <el-date-picker
        v-model="selectDatetime"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期" />
    </el-form-item>
    <el-form-item class="main-form-item-float-right">
      <el-button-group>
        <el-button type="primary" icon="el-icon-search" @click="queryTableData">查询</el-button>
      </el-button-group>
    </el-form-item>
  </el-form>

  <el-table
    :data="tableData"
    border
    stripe
    :default-sort="defaultSort"
    @sort-change="sort"
    style="width: 100%;">
    <el-table-column label="ID" prop="id" width="100px" align="center" sortable="custom" />
    <el-table-column label="创建时间" prop="createTime" min-width="180px" />
    <el-table-column label="用户订单" prop="userOrderId" min-width="100px" />
    <el-table-column label="订单金额" prop="userOrder.amount" min-width="100px" />
    <el-table-column label="申请人类型" prop="generateType" min-width="100px">
      <template #default="scope">
        {{ $store.getters.columnIdToVal('userRefundGType', scope) }}
      </template>
    </el-table-column>
    <el-table-column label="原因" prop="refundReason" min-width="200px" />
    <el-table-column label="状态" prop="status" min-width="100px">
      <template #default="scope">
        {{ $store.getters.columnIdToVal('userRefundStatus', scope) }}
      </template>
    </el-table-column>
    <el-table-column label="审核人ID" prop="reviewer" min-width="100px">
      <template #default="scope">
        {{ scope.row.reviewer === -1 ? '系统': scope.row.reviewer }}
      </template>
    </el-table-column>
    <el-table-column label="审核内容" prop="reviewContent" min-width="200px" />
    <el-table-column
      fixed="right"
      label="操作"
      width="60">
      <template #default="scope">
        <!-- <el-button @click="showDialogForm({data: scope.row, readonly: true})" type="text" size="small">查看</el-button> -->
        <el-button v-if="scope.row.status === 'Wait'" @click="showDialogForm({data: scope.row})" type="text" size="small">审核</el-button>
        <!-- <el-button @click="deleteAciton(scope.row)" type="text" size="small">删除</el-button> -->
      </template>
    </el-table-column>
  </el-table>
  <div style="margin-top: 10px; text-align: right;">
    <el-pagination
      layout="prev, pager, next,jumper"
      :total="total"
      @current-change="applyCurrentPage" />
  </div>

  <el-dialog custom-class="main-el-dialog" :title="dialog.title" v-model="dialog.visibled" width="600px">
    <el-form :model="dialog.form" ref="dialogForm" label-width="80px">
      <el-form-item label="审核内容" prop="reviewContent">
        <el-input type="textarea" v-model="dialog.form.reviewContent" :readonly="dialog.readonly" />
      </el-form-item>
      <el-form-item>
        <el-radio-group v-model="dialog.form.status">
          <el-radio-button label="Pass">通过</el-radio-button>
          <el-radio-button label="Reject">拒绝</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialog.visibled = false">取消</el-button>
        <el-button v-if="!dialog.readonly" type="primary" @click="dialog.submit">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { handleCatchStatus } from '../../api/handler';
import { format } from '../../utils/datetime';

export default {
  data() {
    return {
      selectDatetime: [],
      selectModel: {},
      selectSort: null,
      defaultSort: {prop: 'id', order: 'descending'},
      tableData: [],
      total: 0,
      current: 1,
      dialog: {
        visibled: false,
        created: false,
        readonly: false,
        title: '',
        form: {},
        submit: () => true
      },
      roles: []
    };
  },
  created() {
    this.applySortSelect(this.defaultSort);
    this.queryTableData();
  },
  mounted() {
  },
  methods: {
    queryTableData() {
      this.applyDatetime();
      this.selectModel.sort = this.selectSort;
      this.selectModel.page = this.current - 1;
      this.$api.getOrderRefundApplyPage(this.selectModel)
        .then(data => {
          this.tableData = data.content;
          this.total = data.totalElements;
        })
        .catch(handleCatchStatus);
    },
    applyDatetime() {
      if (this.selectDatetime && this.selectDatetime.length === 2) {
        this.selectModel.startTime = format(this.selectDatetime[0]);
        this.selectModel.endTime = format(this.selectDatetime[1]);
      } else {
        this.selectModel.startTime = null;
        this.selectModel.endTime = null;
      }
    },
    sort({ prop, order }) {
      this.applySortSelect({ prop, order });
      this.queryTableData();
    },
    applySortSelect({ prop, order }) {
      if (order) {
        this.selectSort = `${prop},${order === 'ascending' ? 'ASC' : 'DESC'}`;
        return;
      }
      this.selectSort = null;
    },
    applyCurrentPage(current) {
      this.current = current;
      this.queryTableData();
    },
    showDialogForm({data, created = false, readonly = false}) {
      this.dialog.readonly = readonly;
      this.dialog.created = created;
      this.dialog.visibled = true;
      if (data) {
        this.dialog.form = { ...data };
        this.dialog.form.status = 'Pass';
      }

      let submit;
      if (created) {
        this.dialog.title = '创建';
        this.dialog.form = {};
        submit = () => false;
      } else {
        this.dialog.title = '审核';
        submit = () => this.$api.updateOrderRefundApply(this.dialog.form);
      }

      if (readonly) {
        this.dialog.title = '详情';
        submit = () => false;
      }

      this.dialog.submit = () => this.validateForm(() => submit()
        .then(() => {
          this.$message.success('操作成功');
          this.dialog.visibled = false;
          this.queryTableData();
        })
        .catch(handleCatchStatus));
    },
    validateForm(successFn) {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          successFn();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.dialogForm.resetFields();
    },
    deleteAciton(data) {
      this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => this.$api.deleteCollege(data.id))
        .then(() => {
          this.$message.success('删除成功!');
          this.queryTableData();
        })
        .catch(() => this.$message.info('已取消删除'));
    }
  }
};
</script>
